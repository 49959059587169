import { isEmpty } from "lodash"
import { VALUATION_MODEL } from "~/feature-toggle"
import dayjs from "~/utils/tools/dayjs"
import {
  createStoreDataObject,
  createVendorDataObj,
  getHubData,
  getInitEstimatesDataObj,
  getInitFinancialsDataObj,
  getStockValues,
  getUserInputDataObjsInitialValue,
} from "~/utils/valuationTransform/peModel"
import {
  fetchSingleModelFromBackend,
  fetchAllModels,
  saveNewModelToBackend,
  editModel,
  changeActiveModel,
  fetchLogoData,
  fetchPublicModel,
} from "~/services/valuationModel"
import { hasProp } from "~/utils/screener"
import {
  cellCalculations,
  generateAssumptionsOutput,
  getFormatters,
  generateHistoricalTable,
  getFullfilledDatesFromState,
} from "~/utils/valuationTransform"
import {
  historicalLabelFunctionsByTitle,
  forecastLabelFunctionsByTitle,
  initialFilterState,
  templates,
} from "~/utils/valuationTransform/peValuationTemplates"
import {
  getModelCount,
  checkForModelLimit,
  getTierRestrictions,
} from "~/utils/valuationTransform/models"
import useCiqDataSource from "~/functions/useCiqDataSource"
import { getYearKey } from "~/utils/tools"
import { getAllLogos, save } from "~/utils/tools/logos"

export const state = () => ({
  autosave: true,
  saveEnabled: true,
  isUserLeaving: false,
  isOutdatedData: false,
  loading: {
    allModels: false,
    companyModels: false,
    initialLoad: true,
    saving: false,
    activeModel: false,
  },
  error: {
    message: "",
  },
  data: {
    currentYear: dayjs.utc().year(),
    amountOfYearsForward: 6,
    currentDataset: "",
    storeDataObject: {},
    // an array of objects for each assumptionCase
    userInputsDataArray: [],
    initialUserInputsDataArray: [],
    filters: initialFilterState,
  },
  metadata: {
    title: "",
    note: "",
    updated: true,
    companyid: "",
    tradingitemid: "",
    tickersymbol: "",
    exchangesymbol: "",
    primarytradingitemid: "",
    primarytickersymbol: "",
    primaryexchangesymbol: "",
    companyname: "",
  },
  currentModelId: null,
  createdAt: null,
  lastModified: null,
  // this stores models from every cid/tid
  // every key within this is a cid
  allModels: {},
  templates: {
    assumptionCases: [],
    calculatedMetrics: [],
    estimatesDataArray: [],
    userInputDataArray: [],
    valuationDataArray: [],
    requiredMetrics: [],
    operatingDataRowArray: [],
    summaryDataRowArray: [],
    historicalRows: [],
    forecastRows: [],
    headerMetrics: [],
  },
  dialogs: {
    overwriteActiveModel: false,
    replaceConfirmationDialog: false,
    tierWarning: false,
  },
  switchToggleActiveModel: false,
  isPublicModel: false,
  currentModelPublicId: null,
})

export const mutations = {
  resetState(state) {
    state.isUserLeaving = false
    state.currentModelId = null
    state.isOutdatedData = false
    state.createdAt = null
    state.lastModified = null
    state.loading = {
      allModels: false,
      companyModels: false,
      initialLoad: true,
      saving: false,
    }
    state.data = {
      currentYear: dayjs.utc().year(),
      amountOfYearsForward: 6,
      currentDataset: "",
      storeDataObject: {},
      // an array of objects for each assumptionCase
      userInputsDataArray: [],
      initialUserInputsDataArray: [],
      filters: initialFilterState,
    }
    state.error = {
      message: "",
    }
    state.metadata = {
      title: "",
      note: "",
      updated: true,
      companyid: "",
      tradingitemid: "",
      tickersymbol: "",
      exchangesymbol: "",
      primarytradingitemid: "",
      primarytickersymbol: "",
      primaryexchangesymbol: "",
      companyname: "",
    }
    state.templates = {
      assumptionCases: [],
      calculatedMetrics: [],
      estimatesDataArray: [],
      userInputDataArray: [],
      valuationDataArray: [],
      requiredMetrics: [],
      operatingDataRowArray: [],
      summaryDataRowArray: [],
      historicalRows: [],
      forecastRows: [],
      headerMetrics: [],
    }
    state.dialogs = {
      overwriteActiveModel: false,
      replaceConfirmationDialog: false,
      tierWarning: false,
    }
    state.switchToggleActiveModel = false
    state.isPublicModel = false
    state.currentModelPublicId = null
  },
  setTableData(state, payload) {
    state.tableData = payload
  },
  setAllModels(state, payload) {
    state.allModels = payload
  },
  setModelsForCompany(state, payload) {
    state.allModels = {
      ...state.allModels,
      [payload.companyid]: payload.data,
    }
  },
  setInitialTableData(state, payload) {
    state.data.storeDataObject = payload
  },
  setInitialValues(state, payload) {
    const assumptionCases = transformAssumptionCases(
      payload.templates.assumptionCases,
      payload.templates.userInputDataArray
    )
    const initialData = createStoreDataObject({
      $store: payload.$store,
      isDev: payload.dev,
      parseUtcDateTime: payload.parseUtcDateTime,
      utcEpochToShortDate: payload.utcEpochToShortDate,
      estimatesDataArr: payload.templates.estimatesDataArray,
      valuationDataArr: payload.templates.valuationDataArray,
      computedMetricsArr: payload.templates.calculatedMetrics,
      currentIso: payload.currentIso,
      requiredMetricKeys: payload.templates.requiredMetrics,
    })
    const cid = payload.$store.state.ciq.ticker.companyid
    const models = payload.$store.state.valuationModel.allModels[cid]
    const modelTitle =
      payload.titleOverride ||
      createNameRef(
        payload.$store.state.ciq.ticker,
        payload.utcEpochToShortDate,
        models
      )
    const tickerObj = payload.$store.state.ciq.ticker
    const metadataObj = {
      title: modelTitle,
      companyid: tickerObj.companyid,
      tradingitemid: tickerObj.tradingitemid,
      tickersymbol: tickerObj.tickersymbol,
      exchangesymbol: tickerObj.exchangesymbol,
      primarytradingitemid: tickerObj.primarytradingitemid,
      primarytickersymbol: tickerObj.primarytickersymbol,
      primaryexchangesymbol: tickerObj.primaryexchangesymbol,
      companyname: tickerObj.companyname,
    }
    state.metadata = {
      ...state.metadata,
      ...metadataObj,
    }
    const recommendedObj = initialData.datasets[initialData.recommendedDataset]
    const { data: userInputs, multipliersArr } =
      getUserInputDataObjsInitialValue({
        assumptionCases,
        vendorObj: recommendedObj.initVendorDataObj,
        userInputArray: payload.templates.userInputDataArray,
        mostRecentActualDateKeyStr: recommendedObj.mostRecentActualDateKeyStr,
        amountOfYearsForward: payload.amountOfYearsForward,
        isDPSAvailable: initialData.isDPSAvailable,
        priceOverEarningsThreeYearAvg:
          initialData.priceOverEarningsThreeYearAvg,
      })
    state.templates = {
      ...payload.templates,
      assumptionCases: assumptionCases.map((item, idx) => {
        return {
          description: item.description,
          id: item.id,
          name: item.name,
          multipliersObj: multipliersArr[idx],
        }
      }),
      headerMetrics: payload.templates.headerMetrics.map(mapToMetric),
    }
    const data = {
      amountOfYearsForward: payload.amountOfYearsForward,
      storeDataObject: initialData,
      currentDataset: initialData.recommendedDataset,
      userInputsDataArray: userInputs,
      initialUserInputsDataArray: userInputs,
      currentYear: dayjs.utc().year(),
    }
    state.data = {
      ...state.data,
      ...data,
    }
    state.loading.initialLoad = false
  },
  setEditMode(state, payload) {
    state.currentModelId = payload.uuid
    const { templates, ...rest } = payload.data.data
    const { hubData, ...actualMetadata } = payload.data?.metadata || {}
    state.templates = mapTemplatesToFunction(templates)
    state.data = {
      ...state.data,
      ...rest,
    }
    state.createdAt = payload.data.createdAt
    state.lastModified = payload.data.lastModified
    state.metadata = {
      ...state.metadata,
      ...actualMetadata,
    }
    state.loading.initialLoad = false
  },
  setError(state, payload) {
    state.error.message = payload
  },
  updateTid(state, tid) {
    state.metadata.tradingitemid = tid
  },
  updateCid(state, cid) {
    state.metadata.companyid = cid
  },
  updateLoader(state, payload) {
    const { loaderId } = payload
    state.loading[loaderId] = payload.state
  },
  updateNoteProp(state, note) {
    state.metadata = {
      ...state.metadata,
      note,
      // changing this boolean makes
      // the save button enabled
      updated: false,
    }
  },
  updateTitle(state, title) {
    state.metadata = {
      ...state.metadata,
      title,
      updated: false,
    }
  },
  updateMetadata(state, payload) {
    state.metadata = {
      ...state.metadata,
      ...payload,
    }
  },
  updateUUID(state, uuid) {
    state.currentModelId = uuid
  },
  updateUserInputs(state, payload) {
    state.data.userInputsDataArray = payload
    state.metadata = {
      ...state.metadata,
      updated: false,
    }
  },
  updateCurrentDataset(state, payload) {
    state.data.currentDataset = payload
  },
  updateFilter(state, payload) {
    state.data.filters = {
      ...state.data.filters,
      ...payload,
    }
  },
  updateAutoSave(state, payload) {
    state.autosave = payload
  },
  resetError(state) {
    state.error = {
      message: "",
    }
  },
  setUserLeaving(state, payload) {
    state.isUserLeaving = payload
  },
  updateDialog(state, payload) {
    state.dialogs[payload.dialogId] = payload.value
  },
  toggleSwitchActiveModel(state, payload) {
    state.switchToggleActiveModel = payload
  },
  updateSaveBtn(state, payload) {
    state.saveEnabled = payload
  },
  updateOutdatedProp(state, payload) {
    state.isOutdatedData = payload
  },
  togglePublicModel(state, payload) {
    state.isPublicModel = payload
  },
  setPublicModelId(state, payload) {
    state.currentModelPublicId = payload
  },
}

const fetchLogos = async (companyList, $AmplifyObj, $axiosObj) => {
  const uniqueCompanyList = [...new Set(companyList)].map(Number)

  const result = await fetchLogoData({
    $AmplifyObj,
    $axiosObj,
    cid: uniqueCompanyList,
  })

  return result.data.reduce((acc, item) => {
    acc[item.cid] = item.logo
    return acc
  }, {})
}

export const actions = {
  async fetchAllModels({ commit, dispatch, rootState }, payload) {
    commit("resetError")
    commit("updateLoader", {
      loaderId: "allModels",
      state: true,
    })
    const { data, error } = await fetchAllModels({
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      flagEnabled: VALUATION_MODEL,
      mapLatestData: true,
    })
    if (error) {
      commit("setError", error)
    } else {
      commit("setAllModels", data)
      const storeLogos = getAllLogos()
      // only fetch logos that don't exist in the store
      const dataKeys = Object.keys(data)
      const logosToFetch = payload?.logosToFetch || []
      const keysToLookup = logosToFetch.length
        ? [...logosToFetch, ...dataKeys]
        : dataKeys

      const nonExistingLogos = keysToLookup.reduce((acc, key) => {
        const cidString = String(key)
        if (!storeLogos?.[cidString]?.data) {
          acc.push(key)
          return acc
        }
        return acc
      }, [])

      if (nonExistingLogos.length > 0) {
        dispatch("fetchAllLogos", nonExistingLogos)
      }

      if (payload?.findActiveModel) {
        const cid = String(rootState.ciq.ticker.companyid)
        const cidArr = data?.[cid] || []
        const activeModel = cidArr.find((item) => item.isActive === true)
        if (activeModel && activeModel.modelId !== payload.currentModelId) {
          dispatch("changeDialogState", {
            dialogId: "overwriteActiveModel",
            value: true,
          })
        }
      }
    }
    commit("updateLoader", {
      loaderId: "allModels",
      state: false,
    })
  },
  async fetchAllLogos(_, payload) {
    const logos = await fetchLogos(payload, this.$Amplify, this.$axios)
    save(logos)
  },
  updateTable({ commit }, payload) {
    commit("setTableData", payload)
  },
  async updateNote({ commit, state, dispatch }, payload) {
    const { note, userTier, refCode } = payload
    commit("updateNoteProp", note)
    if (state.autosave) {
      await dispatch("saveOrEditModel", {
        userTier,
        refCode,
      })
    }
  },
  async updateTitle({ commit, state, dispatch }, payload) {
    const { title, userTier, refCode } = payload
    commit("updateTitle", title)
    if (state.autosave) {
      await dispatch("saveOrEditModel", {
        userTier,
        refCode,
      })
    }
  },
  async updateUserInputs({ commit, state, dispatch }, payload) {
    const { updatedObj, userTier, refCode } = payload
    commit("updateUserInputs", updatedObj)
    if (state.autosave) {
      await dispatch("saveOrEditModel", {
        userTier,
        refCode,
      })
    }
  },
  updateDataset({ commit }, payload) {
    commit("updateCurrentDataset", payload)
  },
  async retrieveModelPreview({ commit, dispatch }, payload) {
    const { uuid: publicModelId, userTier } = payload
    commit("resetState")
    if (userTier) {
      dispatch("fetchAllModels")
    }
    const { data, error } = await fetchPublicModel({
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      publicModelId,
      flagEnabled: VALUATION_MODEL,
      userTier,
    })
    const isSameTier = userTier && data.metadata.userTier === userTier
    let editModeData = data
    if (!isSameTier && data) {
      editModeData = changeTierFields(data, userTier)
    }

    if (error) {
      commit("setError", error)
      return
    }

    dispatch("setPublicModelId", data.publicId)
    commit("setEditMode", { uuid: data.modelId, data: editModeData })
  },
  async retrieveModel({ commit, dispatch, rootState, state }, payload) {
    const { uuid, userTier: currentUserTier } = payload
    const cid = rootState.ciq.ticker.companyid
    const models = state.allModels?.[cid] || []
    const activeModel = models.find((item) => item.isActive)
    const activeModelId = activeModel ? activeModel.modelId : false
    commit("resetState")
    dispatch("fetchAllModels", {
      findActiveModel: true,
      currentModelId: uuid,
    })
    const { data, error } = await fetchSingleModelFromBackend({
      uuid,
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      flagEnabled: VALUATION_MODEL,
    })

    if (error) {
      commit("setError", error)
      return
    }

    dispatch("toggleSwitchActiveModel", uuid === activeModelId)
    dispatch("togglePublicModel", data.isPublic)
    dispatch("setPublicModelId", data.publicId)
    const stockFallback = data.data.storeDataObject.stockValues
    const { data: latestStockPrice, fallback } = getlastStock(
      rootState,
      stockFallback
    )
    if (fallback) {
      dispatch("toggleOutdatedData", true)
    }
    const freshData = {
      latestStockPrice,
      stockPriceObj: getStockPriceObj(rootState, stockFallback),
    }
    const modelTier = data.metadata.userTier
    let merged = mergeFreshData(data, freshData)

    if (modelTier !== currentUserTier) {
      merged = updateAuthFields(merged, rootState, payload.isDev)
    }
    commit("setEditMode", { uuid, data: merged })
  },
  async retrievePreviewModel({ commit, dispatch }, payload) {
    const { uuid, userTier } = payload
    dispatch("fetchAllModels")
    const { data, error } = await fetchSingleModelFromBackend({
      uuid,
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      flagEnabled: VALUATION_MODEL,
    })

    if (error || isEmpty(data?.data)) {
      commit("setError", error || "No data found")
      return
    }

    const modelTier = data?.metadata?.userTier
    if (modelTier !== userTier) {
      console.warn("User tier is different than the model one:")
      console.warn("User tier:", userTier, "- Model tier:", modelTier)
    }
    commit("setEditMode", { uuid, data })
  },
  async saveOrEditModel({ state, dispatch }, payload) {
    if (state.currentModelId) {
      await dispatch("editModel", { ...payload, flagEnabled: VALUATION_MODEL })
      await dispatch("fetchAllModels")
      return
    }
    const { userTier } = payload
    const amountOfModels = getModelCount(state.allModels)
    const isLimitOfModelsReached = checkForModelLimit({
      userTier,
      amountOfModels,
    })
    if (isLimitOfModelsReached) {
      dispatch("updateAutoSaveState", false)
      dispatch("toggleEnableSaveBtn", false)
      dispatch("changeDialogState", {
        dialogId: "tierWarning",
        value: true,
      })
      return
    }
    await dispatch("saveModel", {
      ...payload,
      flagEnabled: VALUATION_MODEL,
      toggleActiveModel: amountOfModels === 0,
    })
    await dispatch("fetchAllModels")
  },
  async saveModel({ commit, dispatch, state }, payload) {
    const objToSave = await dispatch("getCurrentModelData", payload)
    const { refCode, toggleActiveModel } = payload
    commit("updateLoader", {
      loaderId: "saving",
      state: true,
    })

    const { data, error } = await saveNewModelToBackend({
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      cid: state.metadata.companyid,
      tid: state.metadata.tradingitemid,
      data: objToSave.data,
      metadata: objToSave.metadata,
      flagEnabled: VALUATION_MODEL,
    })
    if (error) {
      commit("setError", error)
    } else {
      const split = data.split(" ")
      const uuid = split[split.length - 1]
      if (toggleActiveModel) {
        // this is a fresh save so no need to pass any lastActiveModelId
        const { error: toggleError } = await changeActiveModel({
          $AmplifyObj: this.$Amplify,
          $axiosObj: this.$axios,
          cid: state.metadata.companyid,
          tid: state.metadata.tradingitemid,
          modelId: uuid,
          lastActiveModelId: null,
          flagEnabled: VALUATION_MODEL,
        })
        if (!toggleError) {
          commit("updateLoader", {
            loaderId: "saving",
            state: false,
          })
          commit("updateMetadata", { updated: true })
          commit("updateUUID", uuid)
          const newPath = `/stock/model`
          this.$router.replace({
            path: newPath,
            query: {
              cid: state.metadata.companyid,
              tid: state.metadata.tradingitemid,
              ref: refCode,
              modelId: uuid,
            },
          })
        }
        return
      }
      commit("updateLoader", {
        loaderId: "saving",
        state: false,
      })
      commit("updateMetadata", { updated: true })
      commit("updateUUID", uuid)
      const newPath = `/stock/model`
      this.$router.replace({
        path: newPath,
        query: {
          cid: state.metadata.companyid,
          tid: state.metadata.tradingitemid,
          ref: refCode,
          modelId: uuid,
        },
      })
    }
  },
  setNewModel({ commit, dispatch }, payload) {
    const { userTier, refCode, ...rest } = payload
    commit("resetState")
    commit("setInitialValues", rest)
    dispatch("fetchAllModels")
  },
  resetUserInputs({ commit, state }) {
    commit("updateUserInputs", state.data.initialUserInputsDataArray)
  },
  async editModel({ commit, dispatch, state }, payload) {
    const objToSave = await dispatch("getCurrentModelData", payload)
    await editModelFn({
      commit,
      state,
      payload,
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      objToSave,
    })
  },
  updateFilterProps({ commit }, payload) {
    commit("updateFilter", payload)
  },
  updateAutoSaveState({ commit }, payload) {
    commit("updateAutoSave", payload)
  },
  setUserLeaving({ commit }, payload) {
    commit("setUserLeaving", payload)
  },
  changeDialogState({ commit }, payload) {
    commit("updateDialog", payload)
  },
  toggleSwitchActiveModel({ commit }, payload) {
    commit("toggleSwitchActiveModel", payload)
  },
  togglePublicModel({ commit }, payload) {
    commit("togglePublicModel", payload)
  },
  toggleEnableSaveBtn({ commit }, payload) {
    commit("updateSaveBtn", payload)
  },
  async changeActiveModel({ commit, state, rootState }, payload) {
    const onAfterSuccessfulChange = payload?.onAfterSuccessfulChange
    const { currentModelId, allModels } = state
    const cid = String(rootState.ciq.ticker.companyid)
    const tid = rootState.ciq.ticker.tradingitemid
    const models = allModels[cid] || []
    const activeModelId = models.find((item) => item.isActive)?.modelId || null
    // either we pass which one we are changing to
    // or we pass the current active model
    const modelId = payload?.modelId || currentModelId

    commit("updateLoader", {
      loaderId: "activeModel",
      state: modelId,
    })

    const { error } = await changeActiveModel({
      $AmplifyObj: this.$Amplify,
      $axiosObj: this.$axios,
      cid,
      tid,
      modelId,
      lastActiveModelId: activeModelId,
      flagEnabled: VALUATION_MODEL,
    })

    commit("updateLoader", {
      loaderId: "activeModel",
      state: false,
    })

    if (error) {
      console.error(error)
      return
    }

    if (onAfterSuccessfulChange) {
      onAfterSuccessfulChange()
    }
  },
  toggleOutdatedData({ commit }, payload) {
    commit("updateOutdatedProp", payload)
  },
  getCurrentModelData({ state }, payload) {
    const { userTier } = payload
    const hubData = createHubData(state, userTier)
    // filter out "updated" prop from metadata obj
    const filteredOutKeys = ["updated", "userTier", "dataset"]
    const filteredMetadata = Object.keys(state.metadata)
      .filter((key) => !filteredOutKeys.includes(key))
      .reduce(
        (acc, key) => {
          const keyValue = state.metadata[key]
          acc[key] = keyValue
          return acc
        },
        {
          modelType: "pe",
          modelStyle: "advanced",
          userTier,
          hubData,
          dataset: state.data.storeDataObject.recommendedDataset,
        }
      )
    const objToSave = {
      data: {
        ...state.data,
        templates: state.templates,
      },
      metadata: filteredMetadata,
    }
    return objToSave
  },
  setPublicModelId({ commit }, payload) {
    commit("setPublicModelId", payload)
  },
}

const editModelFn = async ({
  commit,
  state,
  payload,
  $AmplifyObj,
  $axiosObj,
  objToSave,
}) => {
  const { flagEnabled } = payload
  commit("updateLoader", {
    loaderId: "saving",
    state: true,
  })

  const { data, error } = await editModel({
    $AmplifyObj,
    $axiosObj,
    cid: state.metadata.companyid,
    tid: state.metadata.tradingitemid,
    data: objToSave.data,
    metadata: objToSave.metadata,
    modelId: state.currentModelId,
    flagEnabled,
  })
  if (error) {
    commit("setError", error)
  } else {
    console.log({ data })
  }
  commit("updateLoader", {
    loaderId: "saving",
    state: false,
  })
}

const transformAssumptionCases = (assumptionCases, userInputDataArray) => {
  return assumptionCases.map((caseObj) => {
    const multipliers = caseObj?.multiplierFn(userInputDataArray)
    return { ...caseObj, multipliers }
  })
}

const createNameRef = (tickerInfoObj, dateFormatter, currentModelList = []) => {
  const today = new Date()
  const formattedDate = dateFormatter.format(today)
  const formattedTitle = `${tickerInfoObj?.tickersymbol} (${formattedDate})`
  const sameTitleModels = currentModelList.filter((item) =>
    item.metadata?.title.startsWith(formattedTitle)
  ).length
  if (sameTitleModels) {
    return `${formattedTitle} (${sameTitleModels})`
  }
  return formattedTitle
}

const mapCellCalculations = (dataArray, keyName) => {
  return dataArray.map((item) => {
    if (hasProp(cellCalculations, item[keyName])) {
      return {
        ...item,
        cellCalc: cellCalculations[item[keyName]],
      }
    }
    return item
  })
}

// reassigning the cellCalculation functions to the templates
const mapTemplatesToFunction = (templates) => {
  const valuationDataArray = mapCellCalculations(
    templates.valuationDataArray,
    "metricKey"
  )

  const estimatesDataArray = mapCellCalculations(
    templates.estimatesDataArray,
    "metricKey"
  )

  const calculatedMetrics = mapCellCalculations(
    templates.calculatedMetrics,
    "newCalculatedMetricKey"
  )

  const historicalRows = templates.historicalRows.map((item) => {
    if (hasProp(historicalLabelFunctionsByTitle, item.label)) {
      return {
        ...item,
        labelFn: historicalLabelFunctionsByTitle[item.label],
      }
    }
    return item
  })

  const forecastRows = templates.forecastRows.map((item) => {
    if (hasProp(forecastLabelFunctionsByTitle, item.label)) {
      return {
        ...item,
        labelFn: forecastLabelFunctionsByTitle[item.label],
      }
    }
    return item
  })

  return {
    ...templates,
    valuationDataArray,
    estimatesDataArray,
    calculatedMetrics,
    historicalRows,
    forecastRows,
    headerMetrics: templates.headerMetrics.map(mapToMetric),
  }
}

const mapToMetric = (obj) => {
  const { cellValue } = useCiqDataSource(obj)
  const finalValue = cellValue.value.endsWith("NaN") ? "-" : cellValue.value
  if (finalValue) {
    return {
      ...obj,
      value: finalValue,
    }
  }
  return obj
}

const getHubUserInputs = (state) => {
  const keysToLookup = Object.keys(state.data.userInputsDataArray[0])
  return state.data?.userInputsDataArray.map((item) => {
    const filteredMetricKeys = Object.keys(item).filter((key) =>
      keysToLookup.includes(key)
    )
    const filteredMetrics = filteredMetricKeys.reduce((acc, key) => {
      acc[key] = item[key]
      return acc
    }, {})
    return filteredMetrics
  })
}

const createHubData = (state, userTier) => {
  const allDates = getFullfilledDatesFromState(state)
  const assumptionsOutput = generateAssumptionsOutput(state)
  const formatters = getFormatters(state)
  const historicalTable = generateHistoricalTable(
    state,
    assumptionsOutput,
    formatters,
    userTier
  )
  const currentDataset = state.data.currentDataset
  const $newStoreDataObject = state.data.storeDataObject
  const initVendorDataObj =
    $newStoreDataObject?.datasets?.[currentDataset]?.initVendorDataObj
  const mostRecentActualDateKeyStr =
    state.data.storeDataObject.mostRecentActualDateKeyStr
  const currencies = $newStoreDataObject?.datasets?.[currentDataset].currencies
  const latestStockPriceObj = initVendorDataObj.stockPrice.latest
  const userInputs = getHubUserInputs(state)
  const fullfilledDates = getFullfilledDatesFromState(state)

  return getHubData({
    allDates,
    assumptionsOutputArray: assumptionsOutput,
    historicalTable,
    latestStockPriceObj,
    mostRecentActualDateKeyStr,
    amountOfYearsFwd: state.data.amountOfYearsForward,
    userInputs,
    fullfilledDates,
    initVendorDataObj,
    currencies,
  })
}

const getlastStock = (rootState, stockFallbackObj) => {
  const tid = rootState?.ciq?.ticker?.tradingitemid
  const priceCloseObjs =
    rootState?.ciq?.addMultiples?.[tid]?.tblDataObj?.priceclose
  if (priceCloseObjs) {
    const lastObj = Object.values(priceCloseObjs).pop()
    return { data: lastObj, fallback: false }
  }

  return { data: stockFallbackObj.latest, fallback: true }
}

const getStockPriceObj = (rootState, stockFallbackObj) => {
  const tid = rootState?.ciq?.ticker?.tradingitemid
  const priceCloseObjs =
    rootState?.ciq?.addMultiples?.[tid]?.resData?.priceclose
  return getStockValues(priceCloseObjs, stockFallbackObj)
}

const mergeFreshData = (data, freshData) => {
  const clone = { ...data }
  const dataset = data?.data?.currentDataset
  const storeDataObj = clone.data.storeDataObject
  const currentDataset = storeDataObj?.datasets?.[dataset]

  storeDataObj.stockValues = freshData?.stockPriceObj
  const mostRecentDateKeyStr = currentDataset.mostRecentActualDateKeyStr
  const yearKeyStockPrice = getYearKey(mostRecentDateKeyStr, 1)

  const stockPriceObj = currentDataset.initVendorDataObj.stockPrice
  const updatedStockPriceObj = Object.keys(stockPriceObj).reduce((acc, key) => {
    if (key === "latest" || key > mostRecentDateKeyStr) {
      acc[key] = freshData.latestStockPrice
      return acc
    }
    acc[key] = stockPriceObj[key]
    return acc
  }, {})

  currentDataset.initVendorDataObj.stockPrice = updatedStockPriceObj
  stockPriceObj[yearKeyStockPrice] = freshData.latestStockPrice

  return {
    ...clone,
  }
}

const updateAuthFields = (data, rootState, isDev) => {
  try {
    const clone = { ...data }
    const storeDataObj = clone.data.storeDataObject
    const dataset = storeDataObj?.recommendedDataset
    const amountOfYearsForward = clone?.data?.amountOfYearsForward
    const mostRecentDatekey = storeDataObj?.mostRecentActualDateKeyStr
    const fullfilledDates = storeDataObj?.datasets?.[dataset]?.fullfilledDates
    const currencies = storeDataObj?.datasets?.[dataset]?.currencies
    const currentIso =
      currencies?.[rootState.valuationModel.data.filters.currency]?.code

    if (dataset === "estimates") {
      const { data: initEstimatesDataObj } = getInitEstimatesDataObj(
        rootState.ciq.estimates,
        templates.estimatesDataArray,
        isDev,
        amountOfYearsForward
      )
      const { data: initVendorDataObjEst } = createVendorDataObj({
        initialData: initEstimatesDataObj,
        $store: {
          state: rootState,
        },
        mostRecentActual: mostRecentDatekey,
        amountOfYearsForward,
        fullfilledDates,
        valuationMetricsArr: templates.valuationDataArray,
        computedMetricsArr: templates.calculatedMetrics,
        currentIso,
        requiredMetricKeys: templates.requiredMetrics,
      })
      storeDataObj.datasets.estimates.initialData = initEstimatesDataObj
      storeDataObj.datasets.estimates.initVendorDataObj = initVendorDataObjEst
      return clone
    }
    const { data: initFinancialsDataObj } = getInitFinancialsDataObj(
      rootState.ciq?.financials?.a?.resData || {},
      mostRecentDatekey,
      amountOfYearsForward,
      templates.estimatesDataArray
    )
    const { data: initVendorDataObjFin } = createVendorDataObj({
      initialData: initFinancialsDataObj,
      $store: {
        state: rootState,
      },
      mostRecentActual: mostRecentDatekey,
      amountOfYearsForward,
      fullfilledDates,
      valuationMetricsArr: templates.valuationDataArray,
      computedMetricsArr: templates.calculatedMetrics,
      currentIso,
      requiredMetricKeys: templates.requiredMetrics,
    })
    storeDataObj.datasets.financials.initialData = initFinancialsDataObj
    storeDataObj.datasets.financials.initVendorDataObj = initVendorDataObjFin
    return clone
  } catch (error) {
    console.log("Error:", error)
    return data
  }
}

/**
 * The `mapStoreMetricsWithCurrentTier` function processes a metrics object (`metricsObj`)
 * and returns a new object where certain date keys are marked as unauthorized (`unauth`).
 *
 * @param {Object} params - Function parameters.
 * @param {Object} params.metricsObj - Object containing the metrics.
 * @param {string} params.mostRecentActualDateKeyStr - String representing the most recent actual date (like 2024##FY).
 * @param {number} params.forecastYears - Number of forecast years.
 * @returns {Object} - New metrics object with certain date keys marked as unauthorized.
 */
const mapStoreMetricsWithCurrentTier = ({
  metricsObj,
  mostRecentActualDateKeyStr,
  forecastYears,
}) => {
  return Object.keys(metricsObj).reduce((acc, metricKey) => {
    const metricDateKeys = Object.keys(metricsObj[metricKey])
    const yearsAhead = metricDateKeys.filter(
      (key) => key > mostRecentActualDateKeyStr
    )
    const keysToBlock = yearsAhead.slice(forecastYears || 0)
    acc[metricKey] = metricDateKeys.reduce((acc2, key) => {
      const obj = metricsObj[metricKey][key]
      if (keysToBlock.includes(key)) {
        acc2[key] = {
          ...obj,
          unauth: true,
        }
        return acc2
      }
      acc2[key] = obj
      return acc2
    }, {})
    return acc
  }, {})
}

const changeTierFields = (data, userTier) => {
  const restrictions = getTierRestrictions(userTier)
  const dataset = data.data.currentDataset
  const storeDataObj = data.data.storeDataObject.datasets?.[dataset]
  const mostRecentActualDateKeyStr = storeDataObj.mostRecentActualDateKeyStr

  const mappedInitVendorDataObj = mapStoreMetricsWithCurrentTier({
    metricsObj: storeDataObj.initVendorDataObj,
    mostRecentActualDateKeyStr,
    forecastYears: restrictions.forecast,
  })

  const mappedInitialData = mapStoreMetricsWithCurrentTier({
    metricsObj: storeDataObj.initialData,
    mostRecentActualDateKeyStr,
    forecastYears: restrictions.forecast,
  })

  const newStoreObj = {
    ...storeDataObj,
    initVendorDataObj: mappedInitVendorDataObj,
    initialData: mappedInitialData,
  }

  return {
    ...data,
    data: {
      ...data.data,
      storeDataObject: {
        ...data.data.storeDataObject,
        datasets: {
          ...data.data.storeDataObject.datasets,
          [dataset]: newStoreObj,
        },
      },
    },
  }
}
