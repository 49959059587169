import { render, staticRenderFns } from "./default.vue?vue&type=template&id=fae3c7d2"
import script from "./default.vue?vue&type=script&setup=true&lang=js"
export * from "./default.vue?vue&type=script&setup=true&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=fae3c7d2&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlgoliaSearchVuetify: require('/vercel/path0/components/AlgoliaSearchVuetify.vue').default,ChangeUserGroup: require('/vercel/path0/components/ChangeUserGroup.vue').default,Onboarding: require('/vercel/path0/components/onboarding/Onboarding.vue').default})
